<template>
<div class="appendedFile">
    <div class="validFile" v-if="fileObject.url">
        <img :src="fileObject.url" :alt="fileObject.name">
        <span class="uploadSuccess">
            <i class="el-icon-upload-success el-icon-check"></i>
        </span>
        <div class="optionsLayer">
            <button :disabled="showProgressBar">
                <i class="el-icon-zoom-in" @click="previewHandle"></i>
            </button>
            <button :disabled="showProgressBar">
                <i class="el-icon-delete" @click="removeHandling"></i>
            </button>
        </div>
    </div>
    <div class="validFile" v-else>
        <el-progress type="circle" :percentage="progress" v-if="showProgressBar"/>
    </div>
</div>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
    data() {
        return {
            base_image_url: process.env.VUE_APP_STORE_IMAGE_BASE_URL,
            progress:0,
            showProgressBar:false,
        }
    },
    props:{
        setFile:{
            type: Function
        },
        removeAsset:{
            type: Function
        },
        removeSingleFile:{
            type: Function
        },
        fileObject:{
            description: 'File object'
        },
        productId:{
            description: 'selected product id'
        },
        indexOfFile:{
            description:'index of file'
        },
    },
    computed:{
        ...mapGetters({
            settings:'settings_module/settings',
        }),
    },
    methods:{
        async uploadFileHandle(){

            if(this.fileObject.type == 'image/jpeg' || this.fileObject.type == 'image/jpg' || this.fileObject.type == 'image/webp' || this.fileObject.type == 'image/png'){
                
                let formData = new FormData();

                formData.append('file',this.fileObject);
                formData.append('fileIndex',this.indexOfFile)

                if(this.productId){

                    formData.append('product_id',this.productId);
                    
                }

                try{
                    const response = await this.$axios.post('/uploadProductImages', formData, {
                        headers: {'Content-Type': 'multipart/form-data',},
                        onUploadProgress: progressEvent => {
                            this.showProgressBar = true
                            this.progress= parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100));
                        }
                    })

                    if(response.data.status_code == '1009'){
                        // this.$notify({
                        //     title: 'success',
                        //     type: 'Success',
                        //     message: response.data.message,
                        // });

                        this.showProgressBar = false;
                        this.progress = 0;

                        let updatedFileObject = {
                            url: this.base_image_url + this.settings.store_id + '/product/' + response.data.image,
                            name: response.data.image,
                            orgName: this.fileObject.name
                        }

                        this.setUpdatedFile(updatedFileObject);

                        this.$store.commit('settings_module/update_remaining_space',response.data.remaining_space.usage_space);

                    }

                }catch(error){
                    
                    this.removeSingleFile(this.fileObject);

                    if(error.response){

                        if(error.response.data.error.includes("storage limit has been reached") || 
                        error.response.data.error.includes('deleted or is no longer available')){

                            this.$message({
                                type: "error",
                                showClose: true,
                                message: error.response.data.error,
                            });

                        }else{

                            this.$message({
                                type: "error",
                                showClose: true,
                                message: error.response.data.message,
                            });

                        }

                    }else{

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.message,
                        });

                    }

                }

            }else{

                if(this.fileObject.type.includes('image/')){

                    let uploadedFileType = this.fileObject.type.slice(6);
                    
                    this.$notify({
                        type: "error",
                        title: "Alert",
                        message: `${uploadedFileType.toUpperCase()} is not a valid file type.`,
                    });

                }else{

                    this.$notify({
                        type: "error",
                        title: "Alert",
                        message: "Uploaded file must be an image.",
                    }); 

                }

            }
        },
        setUpdatedFile(updateFile){

            this.setFile(updateFile);

        },
        async removeHandling(){

            this.removeAsset(this.fileObject);

        },
        previewHandle(){

            this.$emit('preview-event',this.fileObject);

        },

    },
    mounted(){

        if(this.fileObject instanceof File){

            this.uploadFileHandle();

        }

    }
}
</script>

<style>

</style>