<template>
    <draggable 
        class="draggableContainer"
        v-model="draggableData" 
        @end="ListChangeHandle($event)" 
        :animation="80"
        draggable=".draggableItem"
        >
        <div v-for="(file,index) in draggableData" :key="index" class="draggableItem" :draggable="draggableData.length == 1 ? false : true">
          <upload-single-video 
            :fileObject="file"
            :setFile="updateFile"
            :removeAsset="handleRemove"
            :indexOfFile="index"
            :productId="selectedProductId ? selectedProductId : ''"
            :removeSingleFile="singleFileRemoveHandle"
            @preview-event="handleImagePreview"
          />
        </div>
        <div slot="footer" class="addMoreDraggableItem" draggable="false">
            <input 
              type="file" 
              name="file" 
              multiple="multiple" 
              class="uploadInput" 
              @change="uploadHandle($event)" 
              :accept=" filesType == 'images' ? 'image/png,image/jpg,image/jpeg,image/webp' : filesType == 'videos' ? 'video/*' : ''"
            >
            <i class="el-icon-plus"></i>
        </div>
    </draggable>
</template>

<script>
import draggable from 'vuedraggable'
import {mapGetters} from 'vuex';
import { MessageBox, Message } from "element-ui";
import _ from "lodash";
import uploadSingleVideo from "./uploadSingleVideo.vue"
import { v4 as uuidv4 } from 'uuid';

export default {
    data() {
        return {
            url: process.env.VUE_APP_BASE_URL + '/uploadProductImages',
            draggableData:[],
            headers: {},
        }
    },
    props:{
      setFiles:{
        type: Function
      },
      draggableValues:{
        type: Array,
        required: true,
        description: 'Files'
      },
      defaultFiles: {
        type: Array,
        default: ()=>[],
        description: ''
      },
      filesType:{
        type:String,
        required: true,
        description:'type of files'
      },
      selectedProductId:{
        description:'product id'
      },
      onReorder:{
        type: Function
      },
      onRemove:{
        type: Function
      },
      disabled:{
        type:Boolean,
        default: false
      }
    },
    components:{
        draggable,
        uploadSingleVideo,
    },
    computed:{
        ...mapGetters({
            settings:'settings_module/settings',
        }),
    },
    watch:{
        'draggableValues':{
            handler:function(value){

                this.draggableData = _.cloneDeep(value);

            },deep:true
        }
    },
    methods:{
        ListChangeHandle(e){

            if(JSON.stringify(this.draggableData) !== JSON.stringify(this.draggableValues)){

                this.assignOrder(true);

                this.onReorder();

            }

        },
        async uploadHandle(e){

            if(e.target.files.length > 5){

                this.$notify({
                    title: "Alert",
                    type: "error",
                    message: "You are limited to uploading a maximum of 5 videos at a time.",
                }); 

                return

            }

            let validFiles = [];
            
            for(const file of e.target.files){

                if((this.filesType == 'videos') && file.type.includes("video/mp4")){

                    const video = document.createElement('video');
                    const objectURL = URL.createObjectURL(file);
                    
                    video.src = objectURL;

                    let duration = await this.loadVideoMetadata(video);

                    if(duration >= 60){

                        this.$notify({
                            title: "Alert",
                            type: "error",
                            message: "The allowed video duration is limited to one minute (60 seconds).",
                        });

                    }else if(file.size > 52428800){

                        this.$notify({
                            title: "Alert",
                            type: "error",
                            message: "The video size must not exceed 50MBs.",
                        });

                    }else{

                        validFiles.push(file);

                    }

                }else{
                        
                    if(file.type.includes("video/")){

                        if(!file.type.includes("video/mp4")){

                            this.$notify({
                                title: "Alert",
                                type: "error",
                                message: "The file format should be MP4.",
                            });

                        }

                    }else{

                        this.$notify({
                            title: "Alert",
                            type: "error",
                            message: "Uploaded file must be a video.",
                        });

                    }

                }

            }

            for(let i = 0;i < validFiles.length; i++){
                
                this.draggableData.push(validFiles[i]);

            }

            this.setUpdatedData();

        },
        updateFile(file){

            let uuid = uuidv4();

            let fileObj = {
                id:file.id,
                uid: uuid,
                name:file.name,
                status:'success',
                url:file.url,
                raw:{
                    originalFileName: file.orgName
                }
            }

            let index = this.draggableData.findIndex((dataItem) => dataItem instanceof File && dataItem.name == file.orgName)

            if(index != -1){

                this.draggableData.splice(index,0);

                this.draggableData[index] = fileObj;

                this.assignOrder(false);

                this.setUpdatedData();
            }

        },
        setUpdatedData(){

            this.setFiles(this.draggableData);

        },
        handleRemove(file){

            MessageBox.confirm(
                "Are you sure? video will be deleted permanently", 
                "Warning", 
                {
                    confirmButtonText: "Yes",
                    cancelButtonText: "Cancel",
                    type: "warning",
                }
            ).then(async () => {

                let formData = new FormData()

                formData.append('video',file.name);
                formData.append('stored',1);

                try{
                let res = await this.$axios.post('/product/video/remove/single',formData);
                if(res.data.status_code == "5001"){

                    // this.setFiles(fileList);
                    let product = res.data.product;

                    product.seo.keywords = product.seo.keywords.map((key) => {
                        return key.keyword;
                    });

                    this.$notify({
                        type: 'success',
                        title: 'Success',
                        message: res.data.message,
                    });

                    this.setRemoveData(file,product);

                    this.$store.commit('settings_module/update_remaining_space',res.data.remaining_space.usage_space);

                }
                }catch(error){

                    if(error.response){

                        this.$message({
                            type: "error",
                            showClose: true,
                            message: error.response.data.message,
                        });
                        
                    }else{

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.message,
                        });

                    }

                }

            }).catch(()=>{})
        },
        singleFileRemoveHandle(file,product){
        
            let index = this.draggableData.findIndex((dataItem)=> dataItem.name == file.name);

            if(index != -1){

                this.draggableData.splice(index,1);

                this.assignOrder(false);

                this.setUpdatedData();

            }else if(this.draggableData.some((file) =>  file instanceof File)){
                
                this.draggableData.pop();

                this.assignOrder(false);

                this.setUpdatedData();

            }

        },
        removeErrorFiles(){

        },
        setRemoveData(file,product){

            let index = this.draggableData.findIndex((dataItem)=> dataItem.name == file.name);

            if(index != -1){

                this.draggableData.splice(index,1);

                this.assignOrder(false);

                this.onRemove(product);

            }
        },
        handleImagePreview(file){

            this.$emit('preview-event',file);

        },
        assignOrder(setFiles){

            for(let i = 0 ; i < this.draggableData.length ; i++){

                this.draggableData[i].current_index = i;

            }

            if(setFiles){

                this.setUpdatedData();

            }

        },
        async loadVideoMetadata(video){

            return new Promise((resolve, reject) => {

                video.addEventListener('loadedmetadata', () => {

                    resolve(video.duration);

                });

                video.addEventListener('error', (error) => {

                    reject(error);

                });

                video.load();

            });

        },
    },
    mounted(){

        this.draggableData = _.cloneDeep(this.draggableValues);
        
    }
}
</script>

<style>

</style>