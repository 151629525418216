<template>
    <div>
        <el-tag
            v-for="tag in value"
            :key="tag"
            :closable="canClose"
            :disable-transitions="false"
            @close="handleClose(tag)"
            style="margin-right: 10px"
            effect="dark"
        >
            {{ tag }}
        </el-tag>
        <!-- <ValidationObserver> -->
            <!-- <ValidationProvider :name="`${field}`" :rules="field != 'form' ? value.length == 0 ?  {required:true,max:250} : inputValue.length > 0 ? {max:250} : '' : ''" v-slot="{ errors }"> -->
            <ValidationProvider 
                :name="`${field}`" 
                :rules="value.length == 0 ? isRequired ? {required:true,max:30} : {required:false,max:30} : inputValue.length > 0 ? {max:30} : '' " 
                v-slot="{ errors }"
                v-if="field == 'tags'"
            >
                <div v-if="!disabled"> 
                    <el-input
                        v-show="inputVisible"
                        ref="saveTagInput"
                        v-model="inputValue"
                        :placeholder="placeHolder"
                        class="input-new-tag"
                        size="small"
                        @keyup.enter.native="handleInputConfirm"
                    >
                    </el-input>
                    <span class="text-danger">{{ errors[0] }}</span>
                </div>
            </ValidationProvider>
            <ValidationProvider 
                :name="`${field}`" 
                :rules="field != 'form' ? value.length == 0 ?  {required:true,max:250} : inputValue.length > 0 ? {max:250} : '' : ''" 
                v-slot="{ errors }"
                v-else
            >
                <div v-if="!disabled"> 
                    <el-input
                        v-show="inputVisible"
                        ref="saveTagInput"
                        v-model="inputValue"
                        :placeholder="placeHolder"
                        class="input-new-tag"
                        size="small"
                        @keyup.enter.native="handleInputConfirm"
                    >
                    </el-input>
                    <span class="text-danger">{{ errors[0] }}</span>
                </div>
            </ValidationProvider>
        <!-- </ValidationObserver> -->
    </div>
  <!-- <el-button v-else class="button-new-tag" size="small" @click="showInput"
    >+ New Tag</el-button
  > -->
</template>

<script>
import { Notification } from 'element-ui'
import { ValidationObserver } from 'vee-validate';
export default {
    data() {
        return {
            dynamicTags: [],
            inputVisible: true,
            inputValue: "",
        };
    },
    props: {
        value: {
            type: Array,
            required: true,
            description: "List of tags"
        },
        tagType: {
            type: String,
            default: "primary",
            description: "Tag type (primary|danger etc)"
        },
        placeHolder: {
            type: String,
            default: "Add new tag",
            description: "Place holder for tag"
        },
        maxTag: {
            type: Number,
            default: -1,
            description: "Max tags"
        },
        canClose: {
            type: Boolean,
            default: true,
            description: "Can close tag"
        },
        disabled: {
            type: Boolean,
            default: false,
            description: "disable field"
        },
        module: {
            type: String,
            default: "product",
            description: "module usage"
        },
        field: {
            type: String,
            default: "input",
            description: "field name"
        },
        record: {
            type: Object,
            description: "passed record"
        },
        index: {
            type: Number,
            description: "record index"
        },
        isRequired: {
            type: Boolean,
            default: true,
        }
    },
    methods: {
        handleClose(tag){

            this.value.splice(this.value.indexOf(tag), 1);
            if(this.record){

                this.$parent.handleTagsChange(this.record, this.index);

            }

        },
        showInput(){

            this.inputVisible = true;

            this.$nextTick((_) => {
                this.$refs.saveTagInput.$refs.input.focus();
            });

        },
        handleInputConfirm(){

            const inputValue = this.inputValue;
            if(inputValue){

                let find = this.value.filter(tag => {

                    return tag.toLowerCase() == inputValue.toLowerCase();

                });

                if(find.length > 0){

                    if(this.field == 'tags' || this.field == 'variations' || this.field == 'keywords'){

                        Notification({
                            type: "error",
                            title: "Error",
                            message: "Duplicate "+ this.field +" are not allowed",
                        });

                    }else{

                        Notification({
                            type: "error",
                            title: "Error",
                            message: "Duplicate values are not allowed",
                        });

                    }

                }else{
                    // let isValid = true
                    let charCount = true;
                    // if (inputValue.length > 0) {
                    //     var regx = new RegExp(/^\S.*\S$/);
                    //     if(!regx.test((inputValue.replace(/\s/g,' ')).trim())){

                    //        isValid = false
                    //     }

                    // }

                    if(this.field == 'tags' && inputValue.length > 30){
                        
                        charCount = false;

                    }else if(inputValue.length > 250){

                        charCount = false;

                    }

                    // if(inputValue.length > 250){

                    //     charCount = false

                    // }
                    
                    // if(isValid){
                        if(charCount){

                            if(inputValue.trim().length > 0){

                                this.value.push(inputValue.replace(/\s+/g,' ').trim());

                                if(this.module == "product"){

                                    if(this.record){

                                        this.$parent.handleTagsChange(this.record, this.index);

                                    }

                                }
                                
                            }else{

                                this.$notify({
                                    type: "warning",
                                    title: "Alert",
                                    message: `Entered value must contain alphabets, characters or numbers.`,
                                }); 

                            }
                        }else{

                            if(this.field == 'tags'){

                                this.$notify({
                                    type: "warning",
                                    title: "Alert",
                                    message: "Entered value must not exceed 30 characters in length.",
                                });

                            }else{

                                this.$notify({
                                    type: "warning",
                                    title: "Alert",
                                    message: "Entered value must not exceed 250 characters in length.",
                                });

                            }

                        }
                    // }else{
                    //     this.$notify({
                    //         title: "Alert",
                    //         type: "warning",
                    //         message: "invalid format.",
                    //     });
                    //     return 
                    // }

                }
            }
            // this.inputVisible = true
            this.inputValue = "";
        },
    },
    watch: {
        value: {
            handler: function(){
                
                if(this.maxTag == -1){

                    this.inputVisible = true;

                    return;

                }else{

                    if(this.value.length == this.maxTag){

                        this.inputVisible = false;

                    }else{

                        this.inputVisible = true;

                    }

                }

            },
            deep: true
        },
    },
    components: { ValidationObserver }
}
</script>

<style>
.el-tag + .el-tag {
  margin-bottom: 10px;
}
.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}
.input-new-tag {
  width: 100px;
  /* margin-left: 10px; */
  vertical-align: bottom;
}
.el-notification{
    z-index: 99999 !important;
}
</style>
