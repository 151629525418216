<template>
  <div>
    <el-upload
      ref="upload"
      list-type="picture-card"
      :on-preview="handlePictureCardPreview"
      :before-remove="handleBeforeRemove"
      :on-remove="handleRemove"
      multiple
      :auto-upload="true"
      :on-change="handleChange"
      :action="url"
      accept="image/png,image/jpg,image/jpeg,image/webp"
      :headers="headers"
      :fileList="defaultFiles"
      :on-error="errorHandle"
      :before-upload="beforeImageUpload"
      :on-success="uploadSuccessHandle"
      :disabled="disabled"
    >
      <i class="el-icon-plus"></i>
    </el-upload>

    <!-- <el-dialog v-model="dialogVisible">
      <img w-full :src="dialogImageUrl" alt="Preview Image"/>
    </el-dialog> -->

  </div>
</template>
<script>
import {mapGetters} from 'vuex';
import { MessageBox, Message } from "element-ui";

export default {
  data() {
    return {
      dialogImageUrl: '',
      dialogVisible: false,
      url: process.env.VUE_APP_BASE_URL + '/uploadProductImages',
      // base_image_url: process.env.VUE_APP_STORE_IMAGE_BASE_URL,
      headers: {},
    }
  },
  props:{
      setFiles:{
        type: Function
      },
      value:{
        type: Array,
        required: true,
        description: 'Files'
      },
      defaultFiles: {
        type: Array,
        default: ()=>[],
        description: ''
      },
      disabled:{
        type:Boolean,
        default: false
      }
  },
  computed:{
    ...mapGetters({
      settings:'settings_module/settings',
    }),
  },
  methods: {
    async handleBeforeRemove(file){

      if(file.raw && (file.raw.type == 'image/jpeg' || file.raw.type == 'image/jpg' || file.raw.type == 'image/webp' || file.raw.type == 'image/png')){

        return new Promise((resolve, reject) => {
          this.$confirm(
            `Are you sure? image will be deleted permanently`, 
            "Confirmation", 
            {
              confirmButtonText: "Delete",
              cancelButtonText: "Cancel",
              type: "warning",
            }
          ).then(() => {
              resolve(); // Continue with the file removal
            })
            .catch(() => {
              reject(); // Cancel the file removal
            });
        });

      }else{

        if(file.name && ! file.raw){

          return new Promise((resolve, reject) => {

            this.$confirm(
              `Are you sure? image will be deleted permanently`, 
              "Confirmation", 
              {
                confirmButtonText: "Delete",
                cancelButtonText: "Cancel",
                type: "warning",
              }
            ).then(() => {
              resolve(); // Continue with the file removal
            })
            .catch(() => {
              reject(); // Cancel the file removal
            });

          });

        }

      }
    },
    uploadSuccessHandle(response){

      this.$store.commit('settings_module/update_remaining_space',response.remaining_space.usage_space);

    },
    async handleRemove(file, fileList){

      if(file.raw && (file.raw.type == 'image/jpeg' || file.raw.type == 'image/jpg' || file.raw.type == 'image/webp' || file.raw.type == 'image/png')){

        let formData = new FormData();

        if(file.response){

          formData.append('image',file.response.image);

        }else{

          formData.append('image',file.name);

        }
        try{

          let res = await this.$axios.post('/removeSingleProductImage',formData);
          if(res.data.status_code == "10091"){

            this.setFiles(fileList);

            this.$store.commit('settings_module/update_remaining_space',res.data.remaining_space.usage_space);

          }
        }catch(error){

          if(error.response){

            this.$message({
              type: "error",
              showClose: true,
              message: error.response.data.message,
            });

          }else{

            this.$message({
              type: 'error',
              showClose: true,
              message: error.message,
            });

          }

        }
      }else{
        if(file.name && ! file.raw){

          let formData = new FormData();

          formData.append('image',file.name);
          formData.append('stored',1);
          try{
            let res = await this.$axios.post('/removeSingleProductImage',formData);
            if(res.data.status_code == "10091"){

              this.setFiles(fileList);

              this.$store.commit('settings_module/update_remaining_space',res.data.remaining_space.usage_space);

            }
          }catch(error){

            if(error.response){

              this.$message({
                type: "error",
                showClose: true,
                message: error.response.data.message,
              });

            }else{

              this.$message({
                type: 'error',
                showClose: true,
                message: error.message,
              });

            }

          } 

        }else{

          this.$notify({
            type: "warning",
            title: "Alert",
            message: "Invalid image format",
          });

        }
        
      }
    },
    async errorHandle(error,file,fileList){

      const errors = JSON.parse(error.message)

      if(errors.error.includes("Invalid")){

        this.$notify({
          type: 'error',
          title: 'Alert',
          message: errors.error,
        });

      }else if(errors.error.includes("Storage")){

        this.$notify({
          type: 'error',
          title: 'Alert',
          message: errors.error,
        });

      }else{

        this.$notify({
          type: 'error',
          title: 'Alert',
          message: errors.message,
        });

      }

    },
    handlePictureCardPreview(file){

      this.$emit('preview-event',file);

    },
    handleChange(file, fileList){

        // this.value = fileList;

        this.setFiles(fileList);
        
        // fileList.forEach(file=>{
        //     this.value.push(file.raw)    
        // })
        
        // this.value = arr;
        // console.log(this.value);
        // this.value = fileList.map(file=>{
        //     return file.raw;
        // })
    },
    beforeImageUpload(rawFile){

      if(rawFile.type == 'image/jpeg' || rawFile.type == 'image/jpg' || rawFile.type == 'image/webp' || rawFile.type == 'image/png'){

        return true;

      }else{

        return false;

      }
      
    }
  },
  mounted(){

    this.headers["Authorization"] = `Bearer ${this.$store.state.user.token}`;
      
  }
}
</script>
<style scoped>
.el-dialog__body img{
    width: 100%;
}
</style>

